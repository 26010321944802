.post-update-media .ant-upload-select-picture-card,
.post-update-media .ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload.ant-upload-select-picture-card {
    border-radius: 0;
}

.ant-upload-list-picture-card .ant-upload-list-item{
    float: initial;
    width: initial;
    height: initial;
    margin: initial;
}

.post-update-media .ant-upload-list-picture-card-container,
.post-update-media .ant-upload-select-picture-card,
.post-create-form .ant-upload-list-picture-card-container,
.post-create-form .ant-upload-select-picture-card{
    padding: 0;
    margin: 2px;
    width: calc(33% - 4px);
    height: auto;
    position: relative;
}

.post-update-media .site-icon,
.post-create-form .site-icon {
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.2;
    padding: 3px;
}

.post-update-media .placeholder-icon,
.post-create-form .placeholder-icon {
    display: flex;
    font-size: 28px;
    color: #EB008B;
}

@media (min-width: 768px) {

    .post-update-media .ant-upload-list-picture-card-container,
    .post-update-media .ant-upload-select-picture-card,
    .post-create-form .ant-upload-list-picture-card-container,
    .post-create-form .ant-upload-select-picture-card {
        width: calc(25% - 14px);
        margin: 7px;
    }

    .post-update-media .site-icon,
    .post-create-form .site-icon {
            font-size: 14px;
            padding: 6px;
        }

    .post-update-media .placeholder-icon,
    .post-create-form .placeholder-icon {
        display: flex;
        font-size: 36px;
    }
}