.statistics-wrap {
    position: relative;
}

.summary-statistic-holder .item-loader{
    min-height: 80px;
}

.summary-statistic-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0 10px;
    margin-bottom: 0;
    z-index: 3;
    position: relative;
}

.summary-statistic-list li {
    width: 30%;
    text-align: center;
    padding: 10px 5px 6px;
    margin-bottom: 20px;
    border-radius: 0;
}

.summary-statistic-list li .value {
    font-size: 15px;
    line-height: 1.35em;
    font-weight: 700;
}

.summary-statistic-list li .label {
    font-size: 11px;
    line-height: 1.35em;
    margin: 0;
}

@media (max-width: 768px) {
    .summary-statistic-list li:nth-of-type(n + 4) {
        display: none;
    }
}

@media (min-width: 768px) {

    .statistics-wrap:after,
    .statistics-wrap:before{
        min-height: 280px;
        width: 750px;
    }

    .statistics-wrap:after{
        width: 755px;
    }

    .statistics-wrap h1 {
        font: 38px/58px "Titillium Web", sans-serif;
        margin: 35px 0 50px;
    }

    .summary-statistic-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin-bottom: 0;
    }

    .summary-statistic-list li {
        width: 15%;
        text-align: center;
        padding: 10px 5px 14px;
        margin-bottom: 0;
    }
    .summary-statistic-list li .value {
        font-size: 20px;
    }

    .summary-statistic-list li .label {
        font-size: 12px;
    }
}

@media (min-width: 1024px) {

    .summary-statistic-list li .value {
        font-size: 24px;
    }

    .summary-statistic-list li .label {
        font-size: 15px;
    }
}
